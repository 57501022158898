import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import MarkdownField from "../components/MarkdownField"

const termsMD = require("raw-loader!../markdown/terms.md")

const Terms = () => {
  return (
    <Layout>
      <SEO title="Terms" />

      <MarkdownField path={termsMD} />
    </Layout>
  )
}

export default Terms
